<template>
    <div class="h-90 mr-4">
        <div class="row align-items-center justify-content-center" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-3">
                <div class="d-flex align-items-center justify-content-center">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div>
        <div v-else class="pb-10 h-100 bottom-padding">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center ml-2">
                <div class="breadcrumbactive">
                    <div >
                        <span class="pointer" @click="goToListView">Manage Organization</span>
                        <div v-if="$route.path.startsWith('/organization/create')">Create New</div>
                        <div v-else>Edit</div>
                    </div>
                </div>
            </div>
            <div class="create-company-container w-100 h-100 ml-2">
                <div class="create-company-header" style="border-radius: 0 6px 0 0;">
                    <span class="fs-14 text-white">Organization Information </span>
                </div>
                <div class="mt-6 px-10">
                    <div class="row">
                        <div class="col-lg-7 col-md-6 col-sm-12">
                            <div class="w-100">
                                <label class="input-label">Organization Name<span class="pl-1 text-danger">*</span></label>
                                <input type="text" maxlength="100" class="input-field" v-validate="'required'" v-model="organization_name" name="organization_name" placeholder="Enter organization name" style="height: 38px !important;"/>
                                <div class="text-right" style="position: absolute;right: 15px;">
                                    <span class="input-field-length">{{ organization_name.length }}/100</span>
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('organization_name')">
                                    {{errors.first('organization_name')}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-6">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="w-100">
                                <label class="input-label">Description</label>
                                <textarea rows="5" type="text" maxlength="4000" class="input-field input-textarea" v-model="organization_description" name="organization_description" placeholder="Enter description" style="resize: none;"/>
                                <div class="text-right">
                                    <span class="input-field-length">{{4000 - organization_description.length }}/4000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-10">
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="btn btn-outline-secondary" :disabled="create_edit_org" style="width: 120px;border-radius: 5px;" @click="cancel">Cancel</button>
                            <button v-if="$route.params.id" class="btn btn-new-success ml-5" style="width: 120px;" @click="editOrgByid" :disabled="create_edit_org" >Save
                                <hollow-dots-spinner v-if="create_edit_org" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                            <button v-else class="btn btn-new-success ml-5" @click="addOrg" style="width: 120px;" :disabled="create_edit_org" >Save
                                <hollow-dots-spinner v-if="create_edit_org" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" :success_message="success_message" :succes_name="succes_name" modal_name="create_success" @hideSuccess="hideSuccess"></create-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
    </div>
</template>
<script>
import CreateSuccess from './CreateSuccess.vue';
import organization from '../mixins/organization';
import { SweetModal } from 'sweet-modal-vue';
import {HalfCircleSpinner} from 'epic-spinners';
import globals from '../globals';
import axios from 'axios';
import { EventBus } from '../eventBus/nav-bar-event';
export default {
    components: { CreateSuccess, HalfCircleSpinner, SweetModal },
    mixins: [organization],
    data() {
        return {
            organization_name: '',
            organization_description: '',
            create_success: false,
            success_message: '',
            succes_name:'',
            ajax_call_in_progress: false,
            warning_msg: '',
            create_edit_org: false,
        }
    },
    // beforeRouteLeave (to, from, next) {
    //     if(to.path != `/CreateOrganization`){
    //         let message = "<div class='d-block'>Unsaved Organization information Data would be lost, Do you want to proceed?</div>";
    //         let options = {
    //             html: true,
    //             loader: false,
    //             reverse: false, // switch the button positions (left to right, and vise versa)
    //             okText: 'Yes',
    //             cancelText: 'No',
    //             animation: 'fade',
    //             type: 'basic',
    //             verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
    //             verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
    //             backdropClose: true,
    //         };
    //         this.$dialog.confirm(message, options)
    //         .then(function () {
    //             next();
    //         })
    //         .catch(function () {
    //             next(false);
    //         });
    //     } else {
    //         next()
    //     }
    // },
    methods: {
        cancel() {
            this.$router.push('/organization/listview');
        },
        goToListView() {
            this.$router.push('/organization/listview');
        },
        hideSuccess() {
            this.create_success = false;
            setTimeout(() => {
                this.$router.push('/organization/listview');
            }, 500)
        },
        async addOrganization() {
            this.create_edit_org = true;
            let params = {
                name: this.organization_name,
                description: this.organization_description,
            }
            await axios.post(globals.AUTH_SERVICE + "/organization/", params).
            then((response) => {
                if(response.data.status_id == 1) {
                    this.create_success = true;
                    this.success_message = "You have Successfully Created an Organization";
                    this.succes_name = "Organization Created!"
                    EventBus.$emit('personal_info_data_update');
                    setTimeout(() => {
                        this.$modal.show('create_success');
                    }, 500);
                    this.$store.commit("setRefresh", true);
                }
            }).catch((err) => {
                this.create_edit_org = false;
                this.warning_msg = err.response.data.reason;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                }, 3000);
                setTimeout(() => {
                    EventBus.$emit('personal_info_data_update');
                }, 500);
                this.$store.commit("setRefresh", true);
            });
        },
        addOrg() {
            this.$validator.validateAll().then(result => {
                if (result == true) {
                    this.addOrganization();
                }
            })
        },
        async editOrg() {
            this.create_edit_org = true;
            let params = {
                name: this.organization_name,
                description: this.organization_description,
            }
            await axios.patch(globals.AUTH_SERVICE + `/organization/${this.$route.params.id}`, params).
                then((response) => {
                    if(response.data.status_id == 1) {
                        this.$store.commit("setRefresh", true);
                        this.create_success = true;
                        this.success_message = "You have Successfully Updated an Organization";
                        this.succes_name = "Organization Created"
                        EventBus.$emit('personal_info_data_update');
                        setTimeout(() => {
                            this.$modal.show('create_success');
                        }, 500);
                    }
                }).catch((err) => {
                    this.$store.commit("setRefresh", true);
                    this.create_edit_org = false;
                    this.warning_msg = err.response.data.reason;
                    this.$refs.warning_modal.open();
                    EventBus.$emit('personal_info_data_update');
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                });
        },
        editOrgByid() {
            this.$validator.validateAll().then(result => {
                if (result == true) {
                    this.editOrg();
                }
            })
        },
        async getOrgData() {
            this.ajax_call_in_progress = true;
            try {
                let response = await this.getOrganizationById(this.$route.params.id);
                if(response.status_id == 1) {
                    this.organization_name = response.response.name;
                    this.organization_description = response.response.description;
                }
                this.ajax_call_in_progress = false;
            }
            catch(err) {
                this.ajax_call_in_progress = false;
            }
        },
    },
    mounted() {
        if(this.$route.params.id) {
            this.getOrgData();
        }
        // if(this.loggedInUser.has_organization_or_standalone) {
            if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin' || this.loggedInUser.user_role == 'employee') {
                if(this.loggedInUser.user_has == 'standalone' || this.loggedInUser.user_has == 'consultant') {
                    this.$router.push('/company/listview');
                } else if(this.loggedInUser.user_has == 'companies') {
                    this.$router.push('/organization/listview');
                } else if(this.loggedInUser.user_has == 'company') {
                    this.$router.push('/company/listview');
                }
            }
        // } else {
        //     this.$router.push('/company/createcompany');
        // }
    },
    created() {
        const dict = {
            custom: {
                organization_name:{
                    required:() => "Organization name is required"
                }
            }
        } 
        this.$validator.localize("en", dict);       
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        }
    },
}
</script>
<style scoped>
.btn-outline-secondary {
    color: #e82828;
    border: 1px solid #e82828 !important;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #e82828;
    border-color: #e82828 !important;
}
    .create-company-container {
        background-color: #fff;
        padding: 0px 0 50px 0;
        border-radius: 0 6px 0 0;
    }
    .overflow-auto {
        overflow: auto;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        border-radius: 0 6px 0 0;
    }
    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 6px 10px;
    }
    .input-field {
        border-radius: 8px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100%;
    }
    .input-field-length {
        opacity: 0.5;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: #000;
    }
    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }
    @media only screen and (max-width: 768px){
        .bottom-padding{
            padding-bottom: 150px !important;
            margin-right: 5px !important;
        }
    }
</style>